var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"HFcJsu0ZToVdlCRBEJasr"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

//this file configures the initialization of Sentry on the browser.
//the config you add here will be used whenever a page is visited.
//https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs";

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

Sentry.init({
  dsn: SENTRY_DSN,
  //adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 0.0,

  integrations: [
    new Sentry.BrowserTracing({
      tracingOrigins: ["example.com"],
    //... other options
    }),
  ],


  //...
  //note: if you want to override the automatic release value, do not set a
  //`release` value here - use the environment variable `SENTRY_RELEASE`, so
  //that it will also get attached to your source maps
});
