import React, { useEffect } from "react";
import "../styles/index.scss";
import Head from "next/head";
import { useRouter } from "next/router";
import { SWRConfig } from "swr";
import mpTag from "scripts/tag/mpTag";
import Script from "next/script";

function MyApp({ Component, pageProps: { session, ...pageProps } }) {
  const router = useRouter();

  useEffect(() => {
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker.register("/serviceWorker.js").then(function(registration) {
        console.log("Service Worker registration successful with scope: ", registration.scope);
      },
      function(err) {
        console.log("Service Worker registration failed: ", err);
      });
    }


    function routeChangeStart(url){
      if (window.handlePageExit){
        window.handlePageExit();
      }
    }

    //handle page view
    function routeChangeComplete(url){
      mpTag("page-view");
      window.pageId.url = url;
      window.pageExited = false;
      window.pageViewStartTime = Date.now();
    }

    //gtm


    //authenticate the user
    //authenticateClient(store.dispatch);

    router.events.on("routeChangeStart", routeChangeStart);
    router.events.on("routeChangeComplete", routeChangeComplete);
    return () => {
      router.events.off("routeChangeStart", routeChangeStart);
      router.events.off("routeChangeComplete", routeChangeComplete);
    };
  }, [router.pathname, router.events]);


  return (
    <>
      <Head>

        <link rel="preload" href="/fonts/1Ptsg8zYS_SKggPNyCg4TYFq.woff2" as="font" type="font/woff2" crossOrigin="true"/>
        <link rel="preload" href="/fonts/1Ptug8zYS_SKggPNyC0ITw.woff2" as="font" type="font/woff2" crossOrigin="true"/>
        <link rel="preload" href="/fonts/8vIU7ww63mVu7gtR-kwKxNvkNOjw-jHgfY3lDQ.woff2" as="font" type="font/woff2" crossOrigin="true"/>
        <link rel="shortcut icon" href="/favicon.ico"/>
        <script type="text/javascript" dangerouslySetInnerHTML={{ __html: process.env.tagJs }}></script>

      </Head>
      <SWRConfig value={{ }}>
        <Script id="googleAnalytics" strategy="afterInteractive">
          {`setTimeout(()=>{(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','GTM-P7ZRXFG');}, 1000)`}
        </Script>
        <Component {...pageProps} />
      </SWRConfig>
    </>
  );
}

export default MyApp;
